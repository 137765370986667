import React, { PureComponent, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import Measure from "react-measure";
import classnames from "classnames";

import { InjectedIntl, injectIntl } from "react-intl";
import { messages } from "../../../i18n-messages";
import { SelectInput } from "~/core";
import { ACTIVE_YN } from "~/core/picklist";

import { updateSampleLabelSequence, isSampleLabelComposite } from "@ai360/core";
import { IPicklistOption, ISamplePointTablePoint } from "../models";
import "./sample-point-table.css";
import { ITissueSamplePoint } from "@ai360/core/dist/4x/es/api/ag-event/ag-event.4x";

interface IProductivityRatingCellProps {
    intl: InjectedIntl;
    onSetProdRating: (productivityRatingOption: string) => void;
    productivityRatingGuid: string;
    productivityRatingOptions: IPicklistOption[];
}

class ProductivityRatingCell extends PureComponent<IProductivityRatingCellProps> {
    public render() {
        const { productivityRatingGuid, productivityRatingOptions, onSetProdRating } = this.props;
        const { formatMessage } = this.props.intl;

        return (
            <div onClick={this._preventDefault}>
                <SelectInput
                    maxHeight={500}
                    optionIsHiddenKey={ACTIVE_YN}
                    minOptionsWidth={175}
                    onChange={onSetProdRating}
                    options={productivityRatingOptions}
                    placeholderText={formatMessage(messages.ratingTxt)}
                    value={productivityRatingGuid}
                />
            </div>
        );
    }

    private _preventDefault = (evt: any): void => {
        evt.preventDefault();
    };
}

interface ISamplePointTableProps {
    intl: InjectedIntl;
    onSetPointProdRating: (pointIdx: number, productivityRatingOption: string) => void;
    onSetSelectedPointSet: (selectedPointSet: Set<string>) => void;
    productivityRatingOptions: IPicklistOption[];
    samplePoints: ISamplePointTablePoint[] | ITissueSamplePoint[];
    selectedPointSet: Set<string>;
    showProductivityRating: boolean;
}

const SamplePointTable_ = (props: ISamplePointTableProps): JSX.Element => {
    const {
        intl,
        onSetPointProdRating,
        onSetSelectedPointSet,
        productivityRatingOptions,
        samplePoints,
        selectedPointSet,
        showProductivityRating,
    } = props;
    const { formatMessage } = intl;

    //state variables

    const [colWidths, setColWidths] = useState<number[]>([]);
    const [container, setContainer] = useState<any>(null);
    const [containerNode, setContainerNode] = useState<any>(null);
    const [focusedRatingInput, setFocusedRatingInput] = useState<number>(null);
    const [isComposite, setIsComposite] = useState<boolean>(
        isSampleLabelComposite(props.samplePoints as ITissueSamplePoint[])
    );
    const [lastClickedWithoutShiftIndex, setLastClickedWithoutShiftIndex] = useState<number>(null);
    const [samplePointsSequenceMap, setSamplePointsSequenceMap] = useState<Map<string, number>>(
        new Map<string, number>()
    );
    const [scrollLeft, setScrollLeft] = useState<number>(0);
    const [scrollTop, setScrollTop] = useState<number>(0);

    useEffect(() => {
        updateSampleLabelSequence(samplePointsSequenceMap, samplePoints as ITissueSamplePoint[]);
    }, []);

    useEffect(() => {
        setIsComposite(isSampleLabelComposite(samplePoints as ITissueSamplePoint[]));
        const seqMap = samplePointsSequenceMap;
        updateSampleLabelSequence(seqMap, samplePoints as ITissueSamplePoint[]);
        setSamplePointsSequenceMap(seqMap);
    }, [samplePoints]);

    const _getColumnHorizontalPos = (columnIndex: number, isStickyCol: boolean): number => {
        const prevColumnsWidth = colWidths
            .slice(0, columnIndex)
            .reduce((accum, width) => accum + width, 0);
        if (!isStickyCol) {
            return prevColumnsWidth;
        }
        return prevColumnsWidth + scrollLeft;
    };

    const _getHeader = (isStickyRow: boolean): JSX.Element => {
        const stickyColCount = showProductivityRating ? 2 : 1;
        const columnIndices = Array.from({ length: stickyColCount }, (_, idx) => idx);
        const headerCells = columnIndices.map((columnIndex) => {
            const isStickyCol = columnIndex < stickyColCount;
            const className = classnames(
                {
                    "prod-rating": showProductivityRating && columnIndex === 1,
                    "sticky-col": isStickyCol,
                    sticky: isStickyRow,
                },
                "header-cell"
            );

            if (!isStickyRow) {
                const spacerCell = <div className={className}>{columnIndex}</div>;
                return (
                    <Measure
                        key={`header_${columnIndex}`}
                        onMeasure={_setColWidth.bind(this, columnIndex)}
                    >
                        {spacerCell}
                    </Measure>
                );
            }

            const stickyStyle = {
                left: _getColumnHorizontalPos(columnIndex, isStickyCol),
                top: scrollTop,
                width: colWidths[columnIndex],
            };
            return (
                <div key={`header_${columnIndex}`} className={className} style={stickyStyle}>
                    {columnIndex === 0
                        ? formatMessage(messages.idColLabel)
                        : formatMessage(messages.ratingTxt)}
                </div>
            );
        });

        return <div className="header">{headerCells}</div>;
    };

    const _getRow = (samplePoint: ISamplePointTablePoint, pointIndex: number): JSX.Element => {
        const stickyColCount = showProductivityRating ? 2 : 1;
        const columnIndices = Array.from({ length: stickyColCount }, (_, idx) => idx);
        const rowCells = columnIndices.map((columnIndex) => {
            const isStickyCol = columnIndex < stickyColCount;
            const className = classnames(
                {
                    "fixed-container": focusedRatingInput === pointIndex,
                    "prod-rating": showProductivityRating && columnIndex === 1,
                    selected: selectedPointSet.has(samplePoint.eventSampleTissuePointGuid),
                    "sticky-col": isStickyCol,
                    sticky: isStickyCol,
                },
                "body-cell"
            );

            const onClick = (event) => {
                if (event.defaultPrevented) {
                    return;
                }
                _selectPoints(pointIndex, event.ctrlKey, event.shiftKey);
            };

            const handleFocus = showProductivityRating && columnIndex === 1;
            const onFocus = !handleFocus
                ? null
                : () => {
                      setFocusedRatingInput(pointIndex);
                  };
            const onBlur = !handleFocus
                ? null
                : () => {
                      setFocusedRatingInput(null);
                  };

            const stickyStyle = !isStickyCol
                ? null
                : {
                      left: _getColumnHorizontalPos(columnIndex, isStickyCol),
                      width: colWidths[columnIndex],
                  };
            return (
                <div
                    key={`${samplePoint.eventSampleTissuePointGuid}_${columnIndex}`}
                    className={className}
                    onBlur={onBlur}
                    onClick={onClick}
                    onFocus={onFocus}
                    style={stickyStyle}
                >
                    {columnIndex === 0
                        ? _getSamplePointId(samplePoint)
                        : _getSampleProductivityRating(samplePoint, pointIndex)}
                </div>
            );
        });

        for (let columnIndex = 0; columnIndex < stickyColCount; columnIndex++) {
            const className = classnames("body-cell", {
                "prod-rating": showProductivityRating && columnIndex === 1,
            });
            rowCells.splice(
                0,
                0,
                <div
                    key={`${samplePoint.eventSampleTissuePointGuid}_flexspacer_${columnIndex}`}
                    className={className}
                />
            );
        }

        return (
            <div key={`${samplePoint.eventSampleTissuePointGuid}`} className="row">
                {rowCells}
            </div>
        );
    };

    const _getSamplePointId = (samplePoint: ISamplePointTablePoint): JSX.Element => {
        const seqId = samplePointsSequenceMap.get(samplePoint.eventSampleTissuePointGuid);
        return (
            <div className="body-text">
                {samplePoint.sequenceId == null
                    ? "-"
                    : isComposite
                    ? `${samplePoint.sampleId} - ${seqId}`
                    : `${samplePoint.sampleId}`}
            </div>
        );
    };

    const _getSampleProductivityRating = (
        samplePoint: ISamplePointTablePoint,
        pointIndex: number
    ): JSX.Element => {
        return (
            <ProductivityRatingCell
                intl={intl}
                onSetProdRating={onSetPointProdRating.bind(this, pointIndex)}
                productivityRatingGuid={samplePoint.productivityRatingGuid}
                productivityRatingOptions={productivityRatingOptions}
            />
        );
    };

    const _onScroll = (evt: any): void => {
        const { target } = evt;
        if (containerNode == null && container != null) {
            // eslint-disable-next-line react/no-find-dom-node
            setContainerNode(ReactDOM.findDOMNode(container));
        }
        if (target !== containerNode) {
            return;
        }
        setScrollLeft(target.scrollLeft);
        setScrollTop(target.scrollTop);
    };

    const _selectPoints = (pointIndex: number, withCtrl: boolean, withShift: boolean): void => {
        const noModifiers = !withCtrl && !withShift;

        const newSelectedPointSet = new Set<string>();
        if (noModifiers) {
            newSelectedPointSet.add(samplePoints[pointIndex].eventSampleTissuePointGuid);
        } else if (withCtrl) {
            [...selectedPointSet].forEach((eventSampleTissuePointGuid) =>
                newSelectedPointSet.add(eventSampleTissuePointGuid)
            );

            const toggleSamplePoint = samplePoints[pointIndex];
            if (selectedPointSet.has(toggleSamplePoint.eventSampleTissuePointGuid)) {
                newSelectedPointSet.delete(toggleSamplePoint.eventSampleTissuePointGuid);
            } else {
                newSelectedPointSet.add(toggleSamplePoint.eventSampleTissuePointGuid);
            }
        } else {
            console.assert(withShift);

            // Select all options between this option and the last non-select-click
            if (lastClickedWithoutShiftIndex == null) {
                setLastClickedWithoutShiftIndex(0);
            }

            samplePoints
                .slice(
                    Math.min(lastClickedWithoutShiftIndex, pointIndex),
                    Math.max(lastClickedWithoutShiftIndex, pointIndex) + 1
                )
                .forEach((samplePoint) =>
                    newSelectedPointSet.add(samplePoint.eventSampleTissuePointGuid)
                );
        }

        if (!withShift) {
            setLastClickedWithoutShiftIndex(pointIndex);
        }

        onSetSelectedPointSet(newSelectedPointSet);
    };

    const _setColWidth = (columnIndex: number, { width }): void => {
        if (colWidths[columnIndex] === width) {
            return;
        }
        const newColWidths = [...colWidths];
        newColWidths[columnIndex] = width;
        setColWidths([...newColWidths]);
    };

    return (
        <div ref={(ref) => setContainer(ref)} className="sample-point-tbl" onScroll={_onScroll}>
            {_getHeader(false)}
            {_getHeader(true)}
            {samplePoints.map((point, pointIdx) => _getRow(point, pointIdx))}
        </div>
    );
};

export const SamplePointTable = injectIntl(SamplePointTable_);
